<template>
  <v-card class="mx-auto" :disabled="loading" :loading="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'reports',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h6" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right" />
        <v-col cols="12" xs="12" md="4">
          <v-autocomplete
            label="Aseguradora(s)"
            v-model="insurance_ids"
            :items="insurances"
            item-value="id"
            :item-text="(v) => v.name"
            :loading="insurances_ldg"
            dense
            multiple
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-autocomplete
            label="Concentrador(es) contratante(s)"
            v-model="contractor_brand_ids"
            :items="contractor_brands"
            item-value="id"
            :item-text="(v) => v.contractor_brand"
            :loading="contractor_brands_ldg"
            dense
            multiple
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            :disabled="tbl_data.length != 0"
            label="Fecha"
            v-model="filter"
            :items="filters"
            item-value="id"
            :item-text="(v) => v.name"
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="2">
          <DatePicker
            :label="'Inicial'"
            :model.sync="start_date"
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="2">
          <DatePicker
            :label="'Final'"
            :model.sync="end_date"
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            :disabled="tbl_data.length != 0"
            label="Estado"
            v-model="status"
            :items="statuses"
            item-value="id"
            :item-text="(v) => v.name"
            dense
          />
        </v-col>
        <v-col v-if="tbl_data.length == 0" cols="12" xs="12" md="4">
          <v-btn
            block
            small
            color="success"
            @click.prevent="getTblData"
            :loading="loading"
            :disabled="
              insurance_ids.length == 0 || contractor_brand_ids.length == 0
            "
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="12" xs="12" md="4 ">
          <v-btn block small color="info" @click.prevent="tbl_data = []">
            Cambiar parametros
            <v-icon right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="tbl_data.length > 0">
      <v-row dense>
        <v-col cols="12" xs="12" md="2">
          <b>T. pendiente pago:</b>
          {{ numberFormat(general.difference_amount) }}
        </v-col>
        <v-col cols="12" xs="12" md="2">
          <b>Registros:</b> {{ tbl_data.length }}
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-text-field
            v-model="tbl_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-row dense>
            <v-col cols="4">
              <v-btn
                block
                small
                color="warning"
                @click.prevent="reportDownload(1)"
              >
                Reporte 1
                <v-icon right> mdi-file-download </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                block
                small
                color="warning"
                @click.prevent="reportDownload(2)"
              >
                Reporte 2
                <v-icon right> mdi-file-download-outline </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                block
                small
                color="success"
                @click.prevent="general_dlg = true"
              >
                Estadistica
                <v-icon right> mdi-file-table </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-data-table
            item-key="key"
            :items="tbl_data"
            :headers="tbl_headers"
            :search="tbl_search"
            :items-per-page="15"
            :loading="loading"
            dense
            fixed-header
            height="73vh"
          >
            <template v-slot:item.key="{ item }">
              <b>{{ item.key }}</b>
            </template>
            <template v-slot:item.refound_amount="{ item }">
              {{ numberFormat(item.refound_amount) }}
            </template>
            <template v-slot:item.refound_not_covered_amount="{ item }">
              {{ numberFormat(item.refound_not_covered_amount) }}
            </template>
            <template v-slot:item.deducible_amount="{ item }">
              {{ numberFormat(item.deducible_amount) }}
            </template>
            <template v-slot:item.coinsurance_amount="{ item }">
              {{ numberFormat(item.coinsurance_amount) }}
            </template>
            <template v-slot:item.invoice_amount="{ item }">
              {{ numberFormat(item.invoice_amount) }}
            </template>
            <template v-slot:item.total_to_pay="{ item }">
              {{ numberFormat(item.total_to_pay) }}
            </template>
            <template v-slot:item.payment_amount="{ item }">
              {{ numberFormat(item.payment_amount) }}
            </template>
            <template v-slot:item.difference_amount="{ item }">
              {{ numberFormat(item.difference_amount) }}
            </template>
            <template v-slot:item.action="{ item, index }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    dark
                    x-small
                    v-on="on"
                    class="ml-1"
                    color="grey darken-1"
                    @click.prevent="docsDlg(item)"
                  >
                    <v-icon> mdi-folder </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Documentos'" />
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    dark
                    x-small
                    v-on="on"
                    class="ml-1"
                    color="info"
                    @click.prevent="getInvoicePdf(item)"
                  >
                    <v-icon> mdi-text-box </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Factura'" />
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    x-small
                    v-on="on"
                    class="ml-1"
                    color="info"
                    @click.prevent="paymentsDlg(item)"
                    :disabled="item.payments.length == 0"
                  >
                    <v-icon> mdi-account-cash</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Resumen de pagos'" />
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    dark
                    x-small
                    v-on="on"
                    class="ml-1"
                    :color="
                      (item.bill_comment
                        ? !item.bill_comment_response
                          ? 'orange'
                          : 'green'
                        : 'grey') + ' darken-1'
                    "
                    @click.prevent="commentsDlg(item)"
                  >
                    <v-icon>
                      mdi-comment-{{
                        item.bill_comment_discharged
                          ? "check"
                          : item.bill_comment
                          ? !item.bill_comment_response
                            ? "arrow-right"
                            : "arrow-left"
                          : "text"
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Comentarios'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="docs_dlg" scrollable persistent max-width="800">
      <v-card tile :disabled="docs_dlg_ldg" :loading="docs_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> {{ folio }} | DOCUMENTACIÓN </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="docs_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row v-if="docs.length > 0" class="pt-3">
            <v-col v-for="(doc, i) in docs" :key="i" cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="doc.title + ' (' + doc.items.length + ')'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th width="200px" v-text="'Fecha'" />
                          <th width="300px" v-text="'Tipo'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, j) in doc.items" :key="j">
                          <td class="font-weight-bold" v-text="j + 1" />
                          <td v-text="item.date" />
                          <td v-text="item.type ? item.type : 'ARCHIVO'" />
                          <td v-text="item.description" />
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  color="warning"
                                  @click.prevent="
                                    fileDownload(
                                      item.base64,
                                      item.ext,
                                      item.file_name
                                    )
                                  "
                                >
                                  <v-icon> mdi-download </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!docs_dlg_ldg && docs.length == 0" class="pt-3">
            <v-col cols="12">
              <p>SIN REGISTROS PARA MOSTRAR</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="payments_dlg" scrollable persistent max-width="800">
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title> {{ folio }} | RESUMEN DE PAGOS </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="payments_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="payments.length > 0">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th v-text="'Payment date'" />
                      <th v-text="'Payment ID'" />
                      <th v-text="'Payment amount'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(payment, i) in payments" :key="i">
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="payment.paid_date" />
                      <td v-text="payment.payment_id" />
                      <td v-text="numberFormat(payment.amount)" />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bill_comment_dlg" scrollable persistent max-width="800">
      <v-card
        tile
        :disabled="bill_comment_dlg_ldg"
        :loading="bill_comment_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> {{ folio }} | COMENTARIOS </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="bill_comment_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="bill_comment_dlg">
          <v-row class="pt-4">
            <v-col v-if="bill_comment" cols="12">
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(item, i) in bill_comment.bill_comment_details"
                  :key="i"
                  :color="(item.response ? 'green' : 'orange') + ' darken-1'"
                  :icon="
                    'mdi-' +
                    (bill_comment &&
                    bill_comment.discharged &&
                    bill_comment.bill_comment_details.length - 1 == i
                      ? 'check'
                      : 'arrow-' + (item.response ? 'left' : 'right'))
                  "
                  small
                >
                  <div>
                    <div class="font-weight-light" v-text="item.created_at" />
                    <div
                      v-if="item.bill_comment_type_name"
                      class="font-weight-bold"
                      v-text="item.bill_comment_type_name"
                    />
                    <div v-text="item.detail" />
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-col>
            <!-- <v-col v-if="bill_comment_detail" cols="12">
              <v-form
                v-on:submit.prevent
                ref="bill_comment_detail_form"
                lazy-validation
              >
                <v-row dense class="px-5 mx-5">
                  <v-col cols="12">
                    <v-autocomplete
                      label="INS Comments"
                      v-model="bill_comment_detail.bill_comment_type_id"
                      :items="bill_comment_types"
                      item-value="id"
                      :item-text="(v) => v.name"
                      :loading="bill_comment_types_ldg"
                      dense
                      :rules="rules.required"
                    />
                  </v-col> 
                  <v-col cols="12">
                    <v-textarea
                      label="Detalle"
                      v-model="bill_comment_detail.detail"
                      rows="2"
                      dense
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      dark
                      color="orange darken-1"
                      @click.prevent="billCommentDetailSubmit"
                    >
                      Enviar
                      <v-icon right small> mdi-comment-arrow-right </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="claim_dlg" scrollable persistent max-width="800">
      <v-card tile :disabled="claim_dlg_ldg" :loading="claim_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> {{ folio }} | INS CLAIM </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="claim_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="claim_dlg">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-form v-on:submit.prevent ref="claim_form" lazy-validation>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      label="ID"
                      v-model="claim.claim"
                      maxlength="18"
                      type="text"
                      dense
                      :rules="rules.required"
                      counter
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn block color="warning" @click.prevent="claimSubmit">
                      Registar
                      <v-icon right small> mdi-file-sign </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="general_dlg" scrollable persistent max-width="1800">
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title> ESTADISTICA </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="general_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="general_dlg">
          <v-row dense>
            <v-col cols="12" class="mt-4">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption"> OFICINAS </span>
                  </v-toolbar-title>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-text="'#'" />
                          <th v-text="'Nombre'" />
                          <th v-text="'Núm de casos'" />
                          <th v-text="'Monto'" />
                          <th v-text="'Porc. cantidad'" />
                          <th v-text="'Porc. total'" />
                          <!-- <th v-text="'Promedio'" /> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(contractor, i) in general.contractors"
                          :key="i"
                        >
                          <td v-text="`${i + 1}`" />
                          <td v-text="contractor.contractor" />
                          <td v-text="contractor.quantity" />
                          <td v-text="numberFormat(contractor.amount)" />
                          <td>{{ contractor.quantity_percent }}%</td>
                          <td>{{ contractor.amount_percent }}%</td>
                          <!-- <td v-text="numberFormat(contractor.amount)" /> -->
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>
                            <b>
                              {{ general.contractors_quantity }}
                            </b>
                          </td>
                          <td>
                            <b>
                              {{ numberFormat(general.contractors_amount) }}
                            </b>
                          </td>
                          <td />
                          <td />
                          <!-- <td /> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption"> PROVEEDORES </span>
                  </v-toolbar-title>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-text="'#'" />
                          <th v-text="'Nombre'" />
                          <th v-text="'Núm de casos'" />
                          <th v-text="'Monto'" />
                          <th v-text="'Porc. cantidad'" />
                          <th v-text="'Porc. total'" />
                          <!-- <th v-text="'Promedio'" /> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(provider, i) in general.providers" :key="i">
                          <td v-text="`${i + 1}`" />
                          <td v-text="provider.provider" />
                          <td v-text="provider.quantity" />
                          <td v-text="numberFormat(provider.amount)" />
                          <td>{{ provider.quantity_percent }}%</td>
                          <td>{{ provider.amount_percent }}%</td>
                          <!-- <td v-text="numberFormat(provider.amount)" /> -->
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>
                            <b>
                              {{ general.providers_quantity }}
                            </b>
                          </td>
                          <td>
                            <b>
                              {{ numberFormat(general.providers_amount) }}
                            </b>
                          </td>
                          <td />
                          <td />
                          <!-- <td /> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  dateTimeNow,
  msgAlert,
  msgConfirm,
  dateTimeToFile,
  base64ToArrayBuffer,
} from "../../control";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    DatePicker,
    ViewData,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      rules: rules(),
      loading: false,
      insurance_ids: [],
      insurances: [],
      insurances_ldg: true,
      contractor_brand_ids: [],
      contractor_brands: [],
      contractor_brands_ldg: true,
      start_date: "",
      end_date: "",
      filter: 1,
      filters: [
        {
          id: 1,
          name: "CARGADOS EN ASEGURADORA",
        },
        {
          id: 2,
          name: "PAGO DE ASEGURADORA",
        },
        {
          id: 3,
          name: "PAGO A PROVEEDOR",
        },
      ],
      status: 3,
      statuses: [
        {
          id: 1,
          name: "NO PAGADOS",
        },
        {
          id: 2,
          name: "PAGADOS",
        },
        {
          id: 3,
          name: "TODOS",
        },
      ],
      tbl_data: [],
      tbl_search: "",
      tbl_headers: [
        {
          text: "#",
          value: "key",
          filterable: true,
        },
        {
          text: "Folio",
          value: "folio",
          filterable: true,
        },
        {
          text: "Institutional / Professional",
          value: "type",
          filterable: true,
        },
        {
          text: "Customer Name",
          value: "contractor_brand_name",
          filterable: true,
        },
        {
          text: "INS ID",
          value: "insured_enrollment",
          filterable: true,
        },
        {
          text: "Group",
          value: "contractor_name",
          filterable: true,
        },
        {
          text: "Patient Name",
          value: "insured_name",
          filterable: true,
        },
        {
          text: "Patient Last Name",
          value: "insured_last_name",
          filterable: true,
        },
        {
          text: "Date of Birth",
          value: "insured_birthday",
          filterable: true,
        },
        {
          text: "M ID",
          value: "insured_credential",
          filterable: true,
        },
        {
          text: "Office",
          value: "insured_office_address",
          filterable: true,
        },
        {
          text: "Provider Name",
          value: "provider_name",
          filterable: true,
        },
        {
          text: "Inpatient / Outpatient",
          value: "type_patient",
          filterable: true,
        },
        {
          text: "From Date",
          value: "admission_date",
          filterable: true,
        },
        {
          text: "To Date",
          value: "discharged",
          filterable: true,
        },
        {
          text: "Diagnosis",
          value: "icd_name",
          filterable: true,
        },
        {
          text: "Code ICD",
          value: "icd_code",
          filterable: true,
        },
        {
          text: "Description Of Services",
          value: "description",
          filterable: true,
        },
        {
          text: "Invoice Number",
          value: "folio_bill",
          filterable: true,
        },
        {
          text: "Consignment",
          value: "consignment",
          filterable: true,
        },
        {
          text: "Date Sinergia Uploaded",
          value: "insurance_reception_date",
          filterable: true,
        },
        {
          text: "Date SM Paid Provider",
          value: "provider_paid_date",
          filterable: true,
        },
        {
          text: "Amount Entered (Refund)",
          value: "refound_amount",
          filterable: true,
        },
        {
          text: "Expenses Not Covered (Refund)",
          value: "refound_not_covered_amount",
          filterable: true,
        },
        {
          text: "Deductible amount",
          value: "deducible_amount",
          filterable: true,
        },
        {
          text: "Coinsurance",
          value: "coinsurance_percent",
          filterable: true,
        },
        {
          text: "Coinsurance amount",
          value: "coinsurance_amount",
          filterable: true,
        },
        {
          text: "Invoice Amount MXN",
          value: "invoice_amount",
          filterable: true,
        },
        {
          text: "Total to pay MXN",
          value: "total_to_pay",
          filterable: true,
        },
        {
          text: "Payment date",
          value: "payment_date",
          filterable: true,
        },
        {
          text: "Payment ID",
          value: "payment_id",
          filterable: true,
        },
        {
          text: "Payment amount",
          value: "payment_amount",
          filterable: true,
        },
        {
          text: "Differences",
          value: "difference_amount",
          filterable: true,
        },
        {
          text: "INS Comments",
          value: "bill_comment",
          filterable: true,
        },
        {
          text: "SM Comments",
          value: "bill_comment_response",
          filterable: true,
        },
        {
          text: "INS Claim ID",
          value: "claim",
          filterable: true,
        },
        {
          text: "Condition type",
          value: "type_condition",
          filterable: true,
        },
        {
          text: "Sinister type",
          value: "type_sinister",
          filterable: true,
        },
        {
          text: "Sinister Number",
          value: "sinister_number",
          filterable: true,
        },
        {
          text: "Restitution",
          value: "restitution",
          filterable: true,
        },
        {
          text: "Fiscal Name",
          value: "fiscal_name",
          filterable: true,
        },
        {
          text: "",
          filterable: false,
          value: "action",
          sortable: false,
          width: "180",
        },
      ],
      general_dlg: false,
      general: null,
      folio: null,
      payments: [],
      payments_dlg: false,
      // bill_comment_types: [],
      // bill_comment_types_ldg: true,
      bill_comment: null,
      bill_comment_dlg: false,
      bill_comment_dlg_ldg: false,
      bill_comment_detail: null,
      key: null,
      claim: null,
      claim_dlg: false,
      claim_dlg_ldg: false,
      docs: [],
      docs_dlg: false,
      docs_dlg_ldg: false,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getTblData() {
      this.tbl_data = [];
      this.loading = true;

      Axios.post(
        URL_API + "/reports/insurances",
        {
          insurance_ids: this.insurance_ids,
          contractor_brand_ids: this.contractor_brand_ids,
          start_date: this.start_date,
          end_date: this.end_date,
          filter: this.filter,
          status: this.status,
        },
        headersToken(this.login.token)
      ).then((res) => {
        if (res.data.data.list.length == 0) {
          this.$swal.fire(msgAlert("warning", "No se obtuvieron resultados"));
        } else {
          this.tbl_data = res.data.data.list;
          this.general = res.data.data.general;
        }

        this.loading = false;
      });
    },
    getInvoicePdf(item) {
      this.loading = true;

      Axios.post(
        URL_API + "/reports/insurances/invoice",
        item,
        headersToken(this.login.token)
      ).then((res) => {
        this.fileDownload(res.data.data.pdf64, "pdf", "aseguradora_factura");
        this.loading = false;
      });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    paymentsDlg(item) {
      this.folio = item.folio;
      this.payments = item.payments;
      this.payments_dlg = true;
    },
    commentsDlg(item) {
      this.key = item.key;
      this.folio = item.folio;
      this.bill_comment_detail = null;
      this.bill_comment = null;
      this.bill_comment_dlg_ldg = true;
      this.bill_comment_dlg = true;

      Axios.get(
        URL_API +
          "/reports/insurances/bill_comments?rha_bill_id=" +
          item.rha_bill_id +
          "&rs_service_bill_id=" +
          item.rs_service_bill_id,
        headersToken(this.login.token)
      ).then((res) => {
        this.bill_comment = res.data.data;

        const counter = this.bill_comment
          ? this.bill_comment.bill_comment_details.length
          : 0;
        //AJUTAR CONDICIONES
        if (
          counter == 0 ||
          (!this.bill_comment.discharged &&
            this.bill_comment.bill_comment_details[counter - 1].response)
        ) {
          this.bill_comment_detail = {
            id: null,
            detail: "",
            response: false,
            bill_comment_type_id: null,
            bill_comment_id: this.bill_comment ? this.bill_comment.id : null,
            rha_bill_id: item.rha_bill_id,
            rs_service_bill_id: item.rs_service_bill_id,
          };

          if (
            counter > 1 &&
            this.bill_comment.bill_comment_details[counter - 1].response
          ) {
            this.bill_comment_detail.bill_comment_type_id =
              this.bill_comment.bill_comment_details[
                counter - 2
              ].bill_comment_type_id;
          }
        }

        this.bill_comment_dlg_ldg = false;
      });
    },

    reportDownload(type) {
      this.loading = true;

      Axios.post(
        URL_API + "/reports/insurances/report/xls" + type,
        {
          list: this.tbl_data,
        },
        headersToken(this.login.token)
      ).then((res) => {
        this.$swal.fire(
          msgAlert(res.data.success ? "success" : "error", res.data.message)
        );

        if (res.data.success) {
          this.fileDownload(
            res.data.base64,
            "xlsx",
            "aseguradora_reporte" + type
          );
        } else {
          console.log(res.data.message);
        }

        this.loading = false;
      });
    },
    docsDlg(item) {
      this.key = item.key;
      this.folio = item.folio;
      this.docs = [];
      this.docs_dlg_ldg = true;
      this.docs_dlg = true;

      Axios.get(
        URL_API +
          "/reports/insurances/docs?rha_bill_id=" +
          item.rha_bill_id +
          "&rs_service_bill_id=" +
          item.rs_service_bill_id,
        headersToken(this.login.token)
      ).then((res) => {
        if (res.data.success) {
          this.docs = res.data.data;
        } else {
          this.$swal.fire(msgAlert("error", res.data.message));
          console.log(res.data.message);
        }
        this.docs_dlg_ldg = false;
      });
    },
    rhaDocumentDownload(base64, ext, folio) {
      this.service_dialog_loading = true;
      const linkSource = `data:application/${ext};base64,${base64}`;
      const downloadLink = document.createElement("a");
      const fileName = `SM_${folio}_${dateTimeToFile()}.${ext}`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.service_dialog_loading = false;
    },
    rhaBillCommentDialog(rha_bill_comment_id, rha_bill_id, rha_folio, index) {
      this.index = index;
      this.rha_folio = rha_folio;
      this.rha_bill_comment = null;
      this.rha_bill_comment_detail = {
        rha_bill_comment_id: rha_bill_comment_id,
        detail: "",
        response: false,
      };
      this.rha_bill_comment_detail_response = false;
      this.comments_dlg_ldg = true;
      this.comments_dlg = true;

      if (!rha_bill_comment_id) {
        this.rha_bill_comment = {
          email: "",
          email2: "",
          phone: "",
          rha_bill_id: rha_bill_id,
          rha_bill_comment_details: [],
        };
        this.rha_bill_comment_detail_response = true;

        this.comments_dlg_ldg = false;
      } else {
        Axios.get(
          URL_API +
            "/reports/insurances/rha/bill/comments/" +
            rha_bill_comment_id,
          headersToken(this.login.token)
        ).then((response) => {
          this.rha_bill_comment = response.data.data;

          if (
            this.rha_bill_comment.rha_bill_comment_details[
              this.rha_bill_comment.rha_bill_comment_details.length - 1
            ].response
          ) {
            this.rha_bill_comment_detail_response = true;
          }

          this.comments_dlg_ldg = false;
        });
      }
    },
  },
  mounted() {
    // Axios.get(
    //   URL_API + "/online/insurance/bill_comment_types",
    //   headersToken(this.login.token)
    // ).then((resp) => {
    //   this.bill_comment_types = resp.data.data;
    //   this.bill_comment_types_ldg = false;
    // });
    Axios.get(URL_API + "/insurances", headersToken(this.login.token)).then(
      (resp) => {
        this.insurances = resp.data.data;
        this.insurances_ldg = false;
      }
    );
    Axios.get(
      URL_API + "/contractors/brands",
      headersToken(this.login.token)
    ).then((resp) => {
      this.contractor_brands = resp.data.data;
      this.contractor_brands_ldg = false;
    });

    this.start_date = dateTimeNow().substring(0, 8) + "01";
    this.end_date = dateTimeNow().substring(0, 10);
  },
};
</script>